@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 100;
  src: url("work-sans-v18-latin-100.06838c53.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 100;
  src: url("work-sans-v18-latin-100italic.6b6234c6.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 200;
  src: url("work-sans-v18-latin-200.716f296a.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 200;
  src: url("work-sans-v18-latin-200italic.add73671.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 300;
  src: url("work-sans-v18-latin-300.19a84574.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 300;
  src: url("work-sans-v18-latin-300italic.8f08315c.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  src: url("work-sans-v18-latin-regular.3b98682a.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 400;
  src: url("work-sans-v18-latin-italic.7c319d6c.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  src: url("work-sans-v18-latin-500.a2e0907a.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 500;
  src: url("work-sans-v18-latin-500italic.6f83aaba.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  src: url("work-sans-v18-latin-600.f6a9d405.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 600;
  src: url("work-sans-v18-latin-600italic.bfb7c0cf.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  src: url("work-sans-v18-latin-700.ebc03247.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 700;
  src: url("work-sans-v18-latin-700italic.d331e846.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 800;
  src: url("work-sans-v18-latin-800.d790e9a8.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 800;
  src: url("work-sans-v18-latin-800italic.554acfc1.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 900;
  src: url("work-sans-v18-latin-900.cefecc10.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: italic;
  font-weight: 900;
  src: url("work-sans-v18-latin-900italic.e3736b08.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 900;
  src: url("jost-v14-latin-900.56a4f984.woff2") format("woff2");
}

head, body, #app {
  margin: 0;
  padding: 0;
}

body {
  padding-left: var(--sail);
  padding-right: var(--sair);
  padding-top: var(--sait);
  padding-bottom: var(--saib);
  z-index: 0;
}

iframe {
  z-index: -99999;
}

/*# sourceMappingURL=index.453e39cc.css.map */
