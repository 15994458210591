/* work-sans-100 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/work-sans-v18-latin-100.woff2") format("woff2");
}
/* work-sans-100italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/work-sans-v18-latin-100italic.woff2") format("woff2");
}
/* work-sans-200 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/work-sans-v18-latin-200.woff2") format("woff2");
}
/* work-sans-200italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/work-sans-v18-latin-200italic.woff2") format("woff2");
}
/* work-sans-300 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/work-sans-v18-latin-300.woff2") format("woff2");
}
/* work-sans-300italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/work-sans-v18-latin-300italic.woff2") format("woff2");
}
/* work-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/work-sans-v18-latin-regular.woff2") format("woff2");
}
/* work-sans-italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/work-sans-v18-latin-italic.woff2") format("woff2");
}
/* work-sans-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/work-sans-v18-latin-500.woff2") format("woff2");
}
/* work-sans-500italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/work-sans-v18-latin-500italic.woff2") format("woff2");
}
/* work-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/work-sans-v18-latin-600.woff2") format("woff2");
}
/* work-sans-600italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/work-sans-v18-latin-600italic.woff2") format("woff2");
}
/* work-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/work-sans-v18-latin-700.woff2") format("woff2");
}
/* work-sans-700italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/work-sans-v18-latin-700italic.woff2") format("woff2");
}
/* work-sans-800 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/work-sans-v18-latin-800.woff2") format("woff2");
}
/* work-sans-800italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/work-sans-v18-latin-800italic.woff2") format("woff2");
}
/* work-sans-900 - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/work-sans-v18-latin-900.woff2") format("woff2");
}
/* work-sans-900italic - latin */
@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/work-sans-v18-latin-900italic.woff2") format("woff2");
}

/* jost-900 - latin */
@font-face {
  font-display: swap;
  font-family: "Jost";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/jost-v14-latin-900.woff2") format("woff2");
}

head,
body,
#app {
  margin: 0;
  padding: 0;
}

body {
  padding-left: var(--sail);
  padding-right: var(--sair);
  padding-top: var(--sait);
  padding-bottom: var(--saib);
  z-index: 0;
}

iframe {
  z-index: -99999;
}
